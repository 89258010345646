import {
  useContext,
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  Carousel,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap';
import {
  BsInfoCircle,
  BsTelephone,
} from 'react-icons/bs';
import { CgCloseR } from 'react-icons/cg';

import * as config from '../config.js';
import carousel1 from '../css/carousel1.jpg';
import carousel2 from '../css/carousel2.jpg';
import carousel3 from '../css/carousel3.jpg';
import carousel4 from '../css/carousel4.jpg';
import {
  BrandingContext,
  LayoutContext,
  SessionContext,
} from '../libs/contexts.js';
import { asNumber } from '../libs/i18n.js';

export const SideBarMedia = () => {

    const { branding } = useContext(BrandingContext);

    return (
        <CarouselViewer />
        // <MapsViewer />
    );

}

const CarouselViewer = ({ }) => {

    const { branding } = useContext(BrandingContext)
    const [maps, setMaps] = useState({ map1: false, map2: false, map3: false, map4: false });

    useEffect(() => {

        if (branding && branding.map1) {
            let newMaps = {};
            if (Array.isArray(branding.map1)) {
                newMaps.map1 = branding.map1;
            } else {
                newMaps.map1 = branding.map1.split(",");
            }
            if (Array.isArray(branding.map2)) {
                newMaps.map2 = branding.map2;
            } else {
                newMaps.map2 = branding.map2.split(",");
            }
            if (Array.isArray(branding.map3)) {
                newMaps.map3 = branding.map3;
            } else {
                newMaps.map3 = branding.map3.split(",");
            }
            if (Array.isArray(branding.map4)) {
                newMaps.map4 = branding.map4;
            } else {
                newMaps.map4 = branding.map4.split(",");
            }
            setMaps(newMaps);
        }
    }, [branding]);


    return (
        <Carousel slide={false} controls={false} indicators={false} interval={11000} fade>
            {"show" === branding.photo1 &&
                <Carousel.Item>
                    <PhotoItem photoId={1} />
                </Carousel.Item>
            }
            {"show" === branding.photo1 &&
                <Carousel.Item>
                    <PhotoItem photoId={2} />
                </Carousel.Item>
            }
            {"show" === branding.photo3 &&
                <Carousel.Item>
                    <PhotoItem photoId={3} />
                </Carousel.Item>
            }
            {"show" === branding.photo4 &&
                <Carousel.Item>
                    <PhotoItem photoId={4} />
                </Carousel.Item>
            }
            {"hide" !== branding.map1 &&
                <Carousel.Item>
                    <MapItem keywords={maps.map1} zoom={11} />
                </Carousel.Item>
            }
            {"hide" !== branding.map2 &&
                <Carousel.Item>
                    <MapItem keywords={maps.map2} zoom={11} />
                </Carousel.Item>
            }
            {"hide" !== branding.map3 &&
                <Carousel.Item>
                    <MapItem keywords={maps.map3} zoom={11} />
                </Carousel.Item>
            }
            {"hide" !== branding.map4 &&
                <Carousel.Item>
                    <MapItem keywords={maps.map4} zoom={11} />
                </Carousel.Item>
            }
            {/* <Carousel.Item>
                <MapItem keywords={['Wawanesa Insurance', 'Winnipeg', 'Manitoba']} zoom={9} />
            </Carousel.Item>
            <Carousel.Item>
                <MapItem keywords={['Wawanesa Insurance', 'Montreal', 'Quebec']} zoom={9} />
            </Carousel.Item>
            <Carousel.Item>
                <MapItem keywords={['Wawanesa Insurance', 'Vancouver', 'BC']} zoom={9} />
            </Carousel.Item> */}
        </Carousel>
    )

}

const MapsViewer = ({ zoom } = { zoom: 9 }) => {

    return (
        <MapItem keywords={['Wawanesa Insurance', 'Winnipeg', 'Manitoba']} zoom={9} />
    )

}

const MapItem = ({ keywords, zoom }) => {

    const [mapSrc, setMapSrc] = useState('');

    useEffect(() => {

        if (keywords) {

            let keywordItems = [];
            for (let keyword of keywords) {
                keywordItems.push(keyword.replace(/\s+/g, '+'));
            }

            let newSrc = ['https://www.google.com/maps/embed/v1/place?key='];
            newSrc.push(config.googleApiKey);
            newSrc.push('&q=');
            newSrc.push(keywordItems.join(","));
            newSrc.push("&zoom=");
            if (undefined === zoom) {
                zoom = 9;
            }
            newSrc.push(zoom);
            //onsole.log("MAP", newSrc.join(""));
            setMapSrc(newSrc.join(""));

        }

    }, [keywords, zoom]);

    return (
        <iframe referrerPolicy="no-referrer-when-downgrade"
            style={{ border: 0, width: "100%", height: "250px" }}
            src={mapSrc}
            allowFullScreen></iframe>
    );
}

const PhotoItem = ({ photoId }) => {

    // we use hard paths so we can build the images in the static assets for faster delivery over the cdn

    return (
        <>
            {1 === photoId &&
                <Image className="mt-1" src={carousel1} style={{ border: 0, height: "250px" }} />
            }
            {2 === photoId &&
                <Image className="mt-1" src={carousel2} style={{ border: 0, height: "250px" }} />
            }
            {3 === photoId &&
                <Image className="mt-1" src={carousel3} style={{ border: 0, height: "250px" }} />
            }
            {4 === photoId &&
                <Image className="mt-1" src={carousel4} style={{ border: 0, height: "250px" }} />
            }
        </>
    );
}
