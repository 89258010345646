export const gateway = "https://coughlin-api.link.wawanesalife.com";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"coughlin.link.wawanesalife.com": {
		a: {
			broker: "coughlin",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.coughlin.com/privacy-policy",
			scope: "coughlin#branding#coughlin.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "crossroads@coughlin.com",
			video_link: "null",
			map2: "Unit 6 – 1570 Regent Ave. W.,Winnipeg,MB,R2C 3B4",
			environment_id: "stable",
			map1: "Unit 4 – 1170 Taylor Ave.,Winnipeg,MB,R3M 3Z4",
			brands: "coughlin.link.wawanesalife.com",
			map4: "Unit 2-11 Vervain Dr.,Rosser,MB,R0H 1E0",
			map3: "Unit 16-2188 McPhillips St.,Winnipeg,MB,R2V 3C8",
			introduction: "null",
			phone: "204-953-4620",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.coughlin.com",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			approve_user: "link_coughlin",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "coughlin.link.wawanesalife.com",
		},
		b: {
			broker: "coughlin",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.coughlin.com/privacy-policy",
			scope: "coughlin#branding#coughlin.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "crossroads@coughlin.com",
			video_link: "null",
			map2: "Unit 6 – 1570 Regent Ave. W.,Winnipeg,MB,R2C 3B4",
			environment_id: "stable",
			map1: "Unit 4 – 1170 Taylor Ave.,Winnipeg,MB,R3M 3Z4",
			brands: "coughlin.link.wawanesalife.com",
			map4: "Unit 2-11 Vervain Dr.,Rosser,MB,R0H 1E0",
			map3: "Unit 16-2188 McPhillips St.,Winnipeg,MB,R2V 3C8",
			introduction: "null",
			phone: "204-953-4620",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.coughlin.com",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			approve_user: "link_coughlin",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "coughlin.link.wawanesalife.com",
		},
	},
};

//updated on Fri May 17 2024 10:56:41 GMT-0700 (Pacific Daylight Time)
